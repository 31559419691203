.no_space_top .bn-overview .bn-grid__row {
  padding-top: 0;
}
.bn-overview {
  .overview-container {
    display: block;
    width: 100%;
    .title {
      color: $clr__ci--secondary;
      font-size: 2.3em;
      font-weight: $typo__fw--bold;
      margin-bottom: 30px;
    }
    .address-datas {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
      .name {
        font-size: 1.6em;
        min-width: 120px;
        display: inline-block;
      }
      .field {
        font-size: 1.6em;
        display: inline-block;
        font-weight: $typo__fw--bold;
      }
      .address-data_1 {
        margin-right: 25%;
        margin-bottom: 20px;
      }
    }
    .comment_field {
      display: flex;
      align-items: flex-start;
      textarea {
        font-size: 1.8em;
        width: 50%;
        margin-left: 45px;
      }
    }
    .shipping {
      > div {
        display: inline-block;
      }
      .title {
        margin-bottom: 0;
        min-width: calc(15% + 150px);
      }
      .shipping_method {
        font-size: 1.8em;
        > span {
          font-weight: $typo__fw--bold;
        }
      }
      .basket_price {
        text-align: right;
        float: right;
        margin-left: 50px;
        .price_title {
          display: inline-block;
          font-weight: $typo__fw--bold;
          color: #626262;
          font-size: 2em;
        }
        .price {
          display: inline-block;
          font-weight: $typo__fw--bold;
          color: #626262;
          font-size: 2.5em;
        }
      }
    }
    .payment {
      > div {
        display: inline-block;
      }
      .title {
        margin-bottom: 0;
        min-width: calc(15% + 150px);
        float: left;
      }
      .payment_method {
        font-size: 1.8em;
      }
    }
    .total {
      > div {
        display: inline-block;
      }
      .title {
        margin-bottom: 0;
        min-width: calc(15% + 150px);
      }
      .text {
        font-size: 1.8em;
      }
      .basket_price {
        text-align: right;
        float: right;
        .vat {
          font-size: 1.5em;
          color: $clr__ci--tertiary;
          margin-bottom: 10px;
        }
        .total {
          font-size: 3.5em;
          color: $clr__ci--tertiary;
          font-weight: $typo__fw--bold;
        }
      }
    }
    hr {
      margin: 50px 0;
    }
  }
  .submit_wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .submit_3 {
      background-color: $clr__ci--secondary;
      border: 1px solid $clr__ci--secondary;
      border-radius: 23px;
      padding: 8px 45px;
      color: $clr__white;
      text-transform: uppercase;
      font-size: 1.6em;
      font-weight: $typo__fw--bold;
      display: inline-block;
      cursor: pointer;
      float: right;
      margin-top: 40px;
      &:hover {
        background-color: #707070;
        border-color: #707070;
      }
      &.no_click {
        pointer-events: none;
      }
    }
  }
}

@media #{$mq__tablet} {
  .bn-overview {
    .overview-container {
      display: block;
      width: 100%;
      .title {
        font-size: 1.8em;
      }
      .comment_field {
        textarea {
          font-size: 1.4em;
        }
      }
      .shipping {
        .title {
          min-width: calc(15% + 150px);
          margin-right: 20px;
        }
        .shipping_method {
          font-size: 1.4em;
        }
        .basket_price {
          .price_title {
            font-size: 1.6em;
          }
          .price {
            font-size: 2em;
          }
        }
      }
      .payment {
        .title {
          min-width: calc(15% + 150px);
          margin-right: 20px;
        }
        .payment_method {
          font-size: 1.4em;
        }
      }
      .total {
        .title {
          min-width: calc(15% + 150px);
          margin-right: 20px;
        }
        .text {
          font-size: 1.4em;
        }
        .basket_price {
          .vat {
            font-size: 1.4em;
          }
          .total {
            font-size: 2.5em;
          }
        }
      }
      hr {
        margin: 40px 0;
      }
    }
  }
}

@media #{$mq__phablet} {
  .bn-overview {
    .overview-container {
      .title {
        display: block!important;
        margin-right: 0!important;
        float: none!important;
        margin-bottom: 10px!important;
      }
      .comment_field {
        display: block;
        textarea {
          margin-left: 0;
          width: 100%;
        }
      }
      .address-datas {
        .address-data_1 {
          margin-right: 0;
        }
      }
    }
  }
}

@media #{$mq__phone} {
  .bn-overview {
    .overview-container {
      .address-datas {
        .name {
          min-width: 100px;
        }
      }
      .shipping {
        .basket_price {
          text-align: left;
          float: none;
          display: block;
          margin-left: 0;
          margin-top: 10px;
        }
      }
      .total {
        .basket_price {
          text-align: left;
          float: none;
          display: block;
          margin-top: 20px;
          .vat {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}