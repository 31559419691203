@mixin basket_link_button() {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 50px;
  .basket_link {
    margin: 0 12px;
    position: relative;
    &:hover {
      .basket_text {
        text-decoration: underline;
      }
    }
    .basket_text {
      color: #626262;
      font-size: 2em;
      padding: 7px 50px;
      text-transform: uppercase;
      text-align: center;
      border: 1px solid #626262;
      border-radius: 23px;
      font-weight: $typo__fw--bold;
    }
    .basket_items {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ADAF1B;
      border: 1px solid #ADAF1B;
      border-radius: 100%;
      width: 27px;
      height: 27px;
      transform: translateY(-30%);
      .count {
        position: absolute;
        color: #fff;
        font-size: 1.5em;
        line-height: 1;
        font-weight: $typo__fw--bold;
        top: 50%;
        left: 50%;
        transform: translateX(-50%)translateY(-50%);
      }
    }
  }
}

@media #{$mq__tablet}{
  .basket_link {
    .basket_text {
      font-size: 1.6em;
    }
  }
}
@media #{"(max-width: 650px)"}{
  .basket_link {
    margin: 0 6px;
  }
}
@media #{$mq__phone}{
  .basket_link {
    width: 100%;
  }
}