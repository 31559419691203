/*  ==========================================================================
    IMPRESSIONS
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__impressions--arrows: $clr__ci--secondary;
$module-clr__impressions--arrows-hover: $clr__ci--primary;

/*
 * MODULE
 */

.bn-impressions {
  .bn-impressions__arrows {
    @include slider--arrows-v2($module-clr__impressions--arrows,$module-clr__impressions--arrows-hover);
  }
  .bn-impressions__slider {
    overflow: hidden;
    .bn-impressions__image {
      display: block;
      > div {
        overflow: hidden;
      }
      img {
        @include img--responsive();
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
