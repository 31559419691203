.bn-cc-product-list {
  .basket_link_button {
    @include basket_link_button();
  }
  .product_filter {
    display: flex;
    justify-content: flex-start;
    margin: 0 12px 45px;
    .filter_item {
      text-transform: uppercase;
      font-size: 2.3em;
      color: #626262;
      margin-right: 90px;
      cursor: pointer;
      &:hover,
      &.active {
        text-decoration: underline;
      }
    }
  }
  .products {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1664px!important;
    .product {
      width: calc(25% - 24px);
      margin: 0 12px 75px;
      display: flex;
      flex-direction: column;
      .product_top {
        display: block;
        img {
          width: 100%;
          height: auto;
        }
      }
      .product_bottom {
        border: 1px solid #707070;
        border-top: none;
        padding: 20px 20px 75px 20px;
        flex: 1;
        position: relative;
        .product_header {
          color: $clr__ci--secondary;
          text-transform: uppercase;
          font-weight: $typo__fw--bold;
          font-size: 1.8em;
        }
        .product_info1,
        .product_info2 {
          margin-top: 15px;
          .info_title {
            font-size: 1.4em;
            font-weight: $typo__fw--bold;
            color: #626262;
          }
          .info_text {
            font-size: 1.4em;
            font-weight: $typo__fw--light;
            color: #707070;
          }
        }
        .read_more {
          margin-top: 15px;
          text-transform: uppercase;
          text-decoration: underline;
          font-size: 1.2em;
          a {
            color: #626262;
            &:hover {
              color: rgba(#626262, 0.5);
            }
          }
        }
        .product_price_amount {
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          .product_amount {
            color: #626262;
            font-size: 1.5em;
            display: flex;
            .amount_counter {
              display: flex;
              align-items: center;
              margin-left: 10px;
              margin-bottom: 20px;
              .plus,
              .minus {
                border: 1px solid #626262;
                border-radius: 100%;
                cursor: pointer;
                position: relative;
                width: 16px;
                height: 16px;
                .icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%)translateY(-50%);
                }
              }
              .counter {
                margin: 0 5px;
              }
            }
          }
          .product_price {
            text-align: right;
            .price_title {
              display: inline-block;
              font-weight: $typo__fw--bold;
              color: #626262;
              font-size: 1.5em;
            }
            .price {
              display: inline-block;
              font-weight: $typo__fw--bold;
              color: #626262;
              font-size: 2em;
            }
            .price_mwst {
              display: block;
              font-weight: $typo__fw--light;
              color: #626262;
              font-size: 1.2em;
            }
          }
        }
        .basket {
          text-align: center;
          display: block;
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;

          .basket_button {
            background-color: $clr__ci--secondary;
            border: 1px solid $clr__ci--secondary;
            border-radius: 23px;
            padding: 10px 45px;
            color: $clr__white;
            text-transform: uppercase;
            font-size: 1.2em;
            font-weight: $typo__fw--bold;
            display: inline-block;
            cursor: pointer;
            &:hover {
              background-color: #707070;
              border-color: #707070;
            }
            &.remove_from_basket {
              background-color: #707070;
              border: 1px solid #707070;
            }
          }
        }
      }
    }
  }
}

@media #{$mq__laptop}{
  .bn-cc-product-list {
    .products {
      .product {
        width: calc(33% - 24px);
        margin: 0 12px 50px;
      }
    }
  }
}

@media #{$mq__tablet}{
  .bn-cc-product-list {
    .basket_button {
      padding: 0 35px;
    }
    .product_filter {
      margin: 0 12px 20px;
      flex-wrap: wrap;
      .filter_item {
        font-size: 1.8em;
        margin-right: 30px;
        margin-bottom: 10px;
      }
    }
    .products {
      .product {
        width: calc(50% - 16px);
        margin: 0 8px 30px;
        .product_bottom {
          .product_price_amount {
            display: block;
            .product_price {
              text-align: left;
            }
          }
          .basket {
            .basket_button {
              padding: 10px 30px;
            }
          }
        }
      }
    }
  }
}

@media #{"(max-width: 650px)"}{
  .bn-cc-product-list {
    .products {
      .product {
        width: 100%;
        margin: 0 0 30px 0;
        .product_bottom {
          .product_price_amount {
            display: flex;
            .product_price {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

@media #{$mq__phone}{
  .bn-cc-product-list {
    .basket_button {
      padding: 0 15px;
    }
  }
}