.dna-popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(214, 214, 199, 0.5);
  z-index: 100;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  &:not(.js-active) {
    display: none;
  }
  &__content {
    background-color: white;
    border-radius: 15px;
    padding: 15px 20px;
    max-width: 420px;
    position: relative;
    margin: 15px;
  }

  &__close {
    position: absolute;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #2f2333;
  }

  &__text {
    font-size: 16px;
    line-height: 1.4;
  }

  &__buttons {
    margin-top: 15px;
    display: flex;
    align-items: center;
    > :first-child {
      margin-left: 0;
    }
  }

  &__button {
    padding: 10px 15px;
    margin: 0px 2px;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
  }

  .--green {
    background-color: #adaf1b;
    color: white;
    transition: background-color 0.3s ease, box-shadow 0.3s ease ;
    &:hover {
      background-color: #989a18;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .--viola {
    color: white;
    background-color: #2f2333;
    transition: background-color 0.3s ease, box-shadow 0.3s ease ;
    &:hover {
      background-color: #221924;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
  }
}