.product_detail {
  .basket_link_button {
    @include basket_link_button();
    margin-bottom: 0;
  }
  .back_to_shop {
    margin-bottom: 50px;
    .back_to_shop_link {
      font-weight: $typo__fw--bold;
      color: #626262;
      text-transform: uppercase;
      .bn-icon {
        margin-right: 5px;
        font-size: 1.8em;
      }
      span {
        font-size: 2.1em;
      }
    }
  }
  .switch_product {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 1.4em;
    margin-bottom: 15px;
    a {
      color: #626262;
      display: flex;
      align-items: center;
      line-height: 16px;
      &:hover {
        color: rgba(#626262, 0.5);
      }
    }
  }
  .product {
    display: flex;
    width: 100%;
    .product_left {
      width: 25%;
      display: block;
      img {
        width: 100%;
        height: auto;
      }
    }
    .product_right {
      border: 1px solid #707070;
      margin-left: 30px;
      padding: 45px 35px 35px;
      width: calc(75% - 30px);
      .product_header {
        color: $clr__ci--secondary;
        text-transform: uppercase;
        font-weight: $typo__fw--bold;
        font-size: 3.2em;
      }
      .product_text {
        margin: 30px 0;
        font-size: 1.7em;
        font-weight: $typo__fw--light;
        color: #707070;
      }
      .product_info1,
      .product_info2 {
        margin-top: 10px;
        .info_title {
          width: 160px;
          display: inline-block;
          font-size: 1.4em;
          font-weight: $typo__fw--bold;
          color: #626262;
        }
        .info_text {
          display: inline-block;
          font-size: 1.4em;
          font-weight: $typo__fw--light;
          color: #707070;
        }
      }
      .product_right_bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .email_request {
          display: none;
          color: #626262;
          font-size: 2.1em;
          text-decoration: underline;
        }
        .product_price_amount {
          display: flex;
          justify-content: space-between;
          .product_amount {
            color: #626262;
            font-size: 1.6em;
            line-height: 2.5em;
            display: flex;
            .amount_counter {
              display: flex;
              align-items: center;
              margin-left: 10px;
              margin-bottom: 10px;
              .plus,
              .minus {
                border: 1px solid #626262;
                border-radius: 100%;
                cursor: pointer;
                position: relative;
                width: 16px;
                height: 16px;
                .icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%)translateY(-50%);
                }
              }
              .counter {
                margin: 0 5px;
              }
            }
          }
          .product_price {
            text-align: right;
            margin-left: 50px;
            .price_title {
              display: inline-block;
              font-weight: $typo__fw--bold;
              color: #626262;
              font-size: 1.6em;
            }
            .price {
              display: inline-block;
              font-weight: $typo__fw--bold;
              color: #626262;
              font-size: 2.3em;
            }
            .price_mwst {
              display: block;
              font-weight: $typo__fw--light;
              color: #626262;
              font-size: 1.3em;
            }
          }
        }
      }
      .basket {
        margin-top: 30px;
        text-align: right;
        display: block;
        .basket_button {
          background-color: $clr__ci--secondary;
          border: 1px solid $clr__ci--secondary;
          border-radius: 23px;
          padding: 10px 45px;
          color: $clr__white;
          text-transform: uppercase;
          font-size: 1.3em;
          font-weight: $typo__fw--bold;
          display: inline-block;
          cursor: pointer;
          &:hover {
            background-color: #707070;
            border-color: #707070;
          }
          &.remove_from_basket {
            background-color: #707070;
            border: 1px solid #707070;
          }
        }
      }
    }
  }

  .other_products {
    margin-top: 100px;
    .title {
      margin-left: 90px;
      margin-bottom: 30px;
      font-size: 2.5em;
      color: $clr__ci--secondary;
      font-weight: $typo__fw--bold;
    }
    .product_slider {
      width: 100%;
      display: block;
      position: relative;
      .slick-arrows {
        .bn-arrows__arrow {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%)translateX(-50%);
          z-index: 1;
          font-size: 6em;
          cursor: pointer;
          &.bn-arrows__arrow-next {
            left: auto;
            right: 0;
            transform: translateY(-50%)translateX(50%);
          }
        }
      }
      .products {
        width: 100%;
        display: block;
        padding: 0 20px;
      }
      .slider_product {
        padding: 0 40px;
        .product_top {
          display: block;
          img {
            width: 100%;
            height: auto;
          }
        }
        .product_bottom {
          background-color: rgba(#2F2333, 0.05);
          padding: 20px;
          flex: 1;
          .product_header {
            color: $clr__ci--secondary;
            text-transform: uppercase;
            font-weight: $typo__fw--bold;
            font-size: 1.8em;
          }
          .product_info1,
          .product_info2 {
            margin-top: 15px;
            .info_title {
              font-size: 1.4em;
              font-weight: $typo__fw--bold;
              color: #626262;
            }
            .info_text {
              font-size: 1.4em;
              font-weight: $typo__fw--light;
              color: #707070;
            }
          }
          .product_price {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            font-weight: 700;
            color: #626262;
            margin-top: 20px;
            .price_title {
              font-size: 1.6em;
            }
            .price {
              font-size: 2.5em;
            }
          }
        }
      }
    }
  }
}

@media #{$mq__laptop}{
  .product_detail {
    .product {
      .product_left {
        width: 30%;
      }
      .product_right {
        margin-left: 20px;
        padding: 35px 25px 25px;
        width: calc(70% - 20px);
      }
    }
    .other_products {
      margin-top: 60px;
      .title {
        margin-left: 40px;
      }
      .product_slider {
        .slider_product {
          padding: 0 20px;
        }
      }
    }
  }
}

@media #{$mq__tablet}{
  .product_detail {
    .basket_link_button {
      margin-bottom: 25px;
    }
    .back_to_shop {
      margin-bottom: 25px;
    }
    .product {
      display: block;
      .product_left {
        width: 100%;
        padding: 0 50px;
      }
      .product_right {
        margin-left: 0;
        padding: 35px 25px 25px;
        width: 100%;
        .product_header {
          font-size: 2.4em;
        }
        .product_text {
          margin: 20px 0;
          font-size: 1.5em;
        }
        .product_right_bottom {
          margin-top: 20px;
          .email_request {
            font-size: 1.8em;
          }
        }
      }
    }
  }
}

@media #{$mq__phablet}{
  .product_detail {
    .product {
      .product_left {
        padding: 0;
      }
      .product_right {
        padding: 15px;
        .product_header {
          font-size: 2em;
        }
        .product_text {
          font-size: 1.4em;
        }
        .product_right_bottom {
          display: block;
          .email_request {
            font-size: 1.7em;
          }
          .product_price_amount {
            margin-top: 20px;
          }
        }
      }
    }
    .other_products {
      .product_slider {
        .product_slider {
          .products {
            padding: 0 30px;
          }
        }
      }
    }
  }
}

@media #{$mq__phone}{
  .product_detail {
    .product {
      .product_right {
        .product_right_bottom {
          .product_price_amount {
            display: block;
          }
        }
        .basket {
          text-align: center;
          .basket_button {
            padding: 10px 25px;
          }
        }
      }
    }
    .other_products {
      .title {
        margin-left: 0;
      }
      .product_slider {
        .slick-arrows {
          .bn-arrows__arrow,
          .bn-arrows__arrow.bn-arrows__arrow-next {
            transform: translateY(-50%);
            font-size: 4em;
          }
        }
        .products {
          padding: 0 30px;
        }
        .slider_product {
          padding: 0 5px;
        }
      }
    }
  }
}