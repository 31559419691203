/*  ==========================================================================
    REQUEST
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__request--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-request {
  .bn-request__holder {
    //background-color: rgba($module-clr__request--bg,0.1); Optional
    .bn_request_variation__vertical {
      @include request--v1();
    }
  }
}
.bn-form-request .bn-form__groupIcon.btn-Group {
  top: 0px;
}
.bn-form-request .bn-form__groupIcon .bn-iconBtn {
  width: 26px;
  height: 26px;
}



//Disable Offerpreview on Offerpoup & Offerdetail
.popupForm,
.detailForm{
  .ractive--offer-lastminute-Template{
    display: none;
  }
}

//Set White as Standard Background
.bn-form-request .bn-form__grid .bn-form__block{
  background-color: #fff;
}



/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */