.bn-adressform {
  .adressform-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title {
      color: $clr__ci--secondary;
      font-size: 2.3em;
      font-weight: $typo__fw--bold;
      margin-bottom: 20px;
    }
    .text {
      color: $clr__ci--tertiary;
      font-size: 1.8em;
    }
    label:not(.no_width),
    .field_wrap {
      color: $clr__ci--tertiary;
      font-size: 1.8em;
      min-width: 165px;
      display: inline-block;
    }
    .adressform-info {
      width: 45%;
      padding-right: 80px;
      .add_form_field {
        margin: 10px 0 100px;
        label {
          margin-left: 10px;
          display: initial;
        }
      }
      .comment_field {
        display: flex;
        align-items: flex-start;
        textarea {
          font-size: 1.8em;
          width: 100%;
          margin-right: 75px;
        }
      }
    }
    .adressform-form {
      width: 55%;
      margin-top: 40px;
      .std_form_2 {
        margin-top: 30px;
      }
      .std_form {
        .field {
          margin: 10px 0;
        }
        input,
        select {
          width: calc(100% - 165px);
          display: inline-block;
          font-size: 1.8em;
          &.invalid {
            color: $clr__error;
            border-color: $clr__error;
          }
        }
        .field_input_wrap {
          width: calc(100% - 165px);
          display: inline-block;
          .plz {
            width: 17%;
          }
          .city {
            width: calc(83% - 20px);
            margin-left: 20px;
          }
        }
      }
      .consent_marketing {
        margin-left: 165px;
        margin-top: 20px;
        label {
          color: #747474;
          font-size: 1.8em;
          margin-left: 10px;
          display: inline-block;
        }
        .marketing_text {
          color: #747474;
          margin-top: 10px;
          font-size: 1.4em;
          a {
            color: $clr__ci--secondary;
            &:visited {
              color: $clr__ci--secondary;
            }
            &:hover {
              color: $clr__ci--secondary;
              text-decoration: underline;
            }
          }
        }
      }
      .submit_2 {
        background-color: $clr__ci--secondary;
        border: 1px solid $clr__ci--secondary;
        border-radius: 23px;
        padding: 8px 45px;
        color: $clr__white;
        text-transform: uppercase;
        font-size: 1.6em;
        font-weight: $typo__fw--bold;
        display: inline-block;
        cursor: pointer;
        float: right;
        margin-top: 40px;
        &:hover {
          background-color: #707070;
          border-color: #707070;
        }
      }
    }
  }
}

@media #{$mq__laptop} {
  .bn-adressform {
    .adressform-container {
      label:not(.no_width),
      .field_wrap {
        min-width: 110px;
      }
      .adressform-info {
        width: 50%;
        padding-right: 60px;
        .comment_field {
          textarea {
            margin-right: 40px;
          }
        }
      }
      .adressform-form {
        width: 50%;
        .std_form {
          input,
          select {
            width: calc(100% - 110px);
          }
          .field_input_wrap {
            width: calc(100% - 110px);
            .city {
              width: calc(83% - 10px);
              margin-left: 10px;
            }
          }
        }
        .consent_marketing {
          margin-left: 110px;
        }
      }
    }
  }
}

@media #{$mq__tablet} {
  .bn-adressform {
    .adressform-container {
      display: block;
      label:not(.no_width),
      .field_wrap {
        min-width: 165px;
      }
      .adressform-info {
        width: 100%;
        padding-right: 0;
        .add_form_field {
          margin: 10px 0 50px;
        }
        .comment_field {
          textarea {
            margin-right: 0;
          }
        }
      }
      .adressform-form {
        width: 100%;
        .std_form {
          input,
          select {
            width: calc(100% - 165px);
          }
          .field_input_wrap {
            width: calc(100% - 165px);
          }
        }
        .consent_marketing {
          margin-left: 165px;
        }
      }
    }
  }
}

@media #{$mq__phablet} {
  .bn-adressform {
    .adressform-container {
      .title {
        font-size: 2em;
      }
      .text {
        font-size: 1.6em;
      }
      label:not(.no_width),
      .field_wrap {
        font-size: 1.6em;
        min-width: 120px;
      }
      .comment_field {
        textarea {
          font-size: 1.6em;
        }
      }
      .adressform-form {
        .std_form {
          input,
          select {
            width: calc(100% - 120px);
            font-size: 1.6em;
          }
          .field_input_wrap {
            width: calc(100% - 120px);
            .plz {
              width: 25%;
            }
            .city {
              width: calc(75% - 10px);
            }
          }
        }
        .consent_marketing {
          margin-left: 0;
          label {
            font-size: 1.6em;
          }
          .marketing_text {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}

@media #{$mq__phone} {
  .bn-adressform {
    .adressform-container {
      .adressform-info {
        .comment_field {
          display: block;
        }
      }
      .adressform-form {
        .std_form {
          input,
          select {
            width: 100%;
          }
          .field_input_wrap {
            width: 100%;
          }
        }
      }
    }
  }
}