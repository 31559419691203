.scrollingButtons {
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .basket_link {
    margin: 0!important;
  }
  .basket_link_button {
    background-color: $clr__ci--primary;
    border: 1px solid $clr__ci--primary;
    border-radius: 19px 0 0 19px;
    padding: 10px 40px 10px 15px;
    display: flex;
    .wrap {
      position: relative;
      .bn-icon {
        color: $clr__white;
        font-size: 50px;
      }
      .basket_items {
        position: absolute;
        top: 4px;
        right: -8px;
        background-color: #ADAF1B;
        border: 1px solid #ADAF1B;
        border-radius: 100%;
        width: 22px;
        height: 22px;
        transform: translateY(-30%);
        .count {
          position: absolute;
          color: $clr__white;
          font-size: 1.5em;
          line-height: 1;
          font-weight: $typo__fw--bold;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
  .voucher-button {
    background-color: #ADAF1B;
    border: 1px solid #ADAF1B;
    border-radius: 17px 0 0 17px;
    padding: 10px 15px;
    color: $clr__white;
    margin-top: 15px;
    font-size: 2.3em;
    font-weight: $typo__fw--bold;
    text-transform: uppercase;
    .voucher-icon {
      display: none;
    }
  }
}
@media #{$mq__tablet} {
  .scrollingButtons {
    .basket_link_button {
      padding: 5px 20px 5px 10px;
      .wrap .bn-icon {
        font-size: 40px;
      }
    }
    .voucher-button {
      .voucher-text {
        display: none;
      }
      .voucher-icon {
        display: block;
      }
    }
  }
}