.no_space_bottom .bn-basket .bn-grid__row {
  padding-bottom: 0;
}
.bn-basket {
  .no_items {
    font-size: 2em;
    padding: 30px 0;
  }
  .basket-container {
    display: block;
    width: 100%;
  }

  .bn-basket_delete {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
    .bn-basket_remove {
      font-size: 1.4em;
      font-weight: $typo__fw--bold;
      padding: 10px 40px;
      border: 1px solid #626262;
      color: #626262;
      border-radius: 23px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .bn-basket_table {
    width: 100%;
    .bn-basket_table_wrap {
      width: 100%;
      > div {
        width: 100%;
        > div {
          display: flex;
          .row {
            padding: 25px 0;
          }
          .first-row,
          .row {
            border-bottom: 1px solid #707070;
            border-right: 1px solid #707070;
            text-align: center;
            font-weight: $typo__fw--bold;
            font-size: 1.6em;
            width: calc(25% / 3);
            min-width: 80px;
            &:first-child {
              border-left: 1px solid #707070;
              text-align: left;
              padding-left: 20px;
              width: 75%;
            }
          }
          .first-row {
            border-top: 1px solid #707070;
            font-weight: $typo__fw--regular;
            font-size: 2em;
          }
          .amount {
            .product_amount {
              display: flex;
              justify-content: center;
              .amount_counter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 80%;
                .plus,
                .minus {
                  border: 1px solid #626262;
                  border-radius: 100%;
                  cursor: pointer;
                  position: relative;
                  width: 16px;
                  height: 16px;
                  .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                  }
                }
                .counter {
                  margin: 0 5px;
                }
              }
            }
          }
          &.product_id_holder {
            position: relative;
            .basket_button {
              content: "";
              position: absolute;
              right: -26px;
              top: 50%;
              transform: translateY(-50%);
              border: 10px solid #626262;
              border-radius: 100%;
              width: 20px;
              height: 20px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 7px;
              font-weight: $typo__fw--bold;
              cursor: pointer;
            }
          }
        }
      }
    }
    .basket-min_value {
      text-align: right;
      font-size: 1.6em;
      margin: 15px 0 30px;
      color: $clr__ci--tertiary;
      &.invalid {
        color: $clr__error;
      }
    }
  }
  .basket-infos {
    width: 100%;
    .title {
      color: $clr__ci--secondary;
      font-size: 2.3em;
      font-weight: $typo__fw--bold;
    }
    .subtitle {
      color: $clr__ci--secondary;
      font-size: 2.1em;
      margin-bottom: 25px;
    }
    .text {
      color: $clr__ci--tertiary;
      font-size: 1.8em;
    }
    hr {
      margin: 30px 0;
    }
    .basket-payment_mode {
      label {
        display: block;
        margin-bottom: 10px;
      }
      select {
        font-size: 1.6em;
        padding: 8px 20px 8px 8px;
        &.invalid {
          color: $clr__error;
          border-color: $clr__error;
        }
      }
      .basket_price {
        text-align: right;
        float: right;
        margin-left: 50px;
        .price_title {
          display: inline-block;
          font-weight: $typo__fw--bold;
          color: #626262;
          font-size: 2em;
        }
        .price {
          display: inline-block;
          font-weight: $typo__fw--bold;
          color: #626262;
          font-size: 2.5em;
        }
        .price_mwst {
          display: block;
          font-weight: $typo__fw--light;
          color: #626262;
          font-size: 1.5em;
        }
      }
    }
    .basket-payment_method {
      .method {
        margin: 40px 0;
        &.invalid {
          label {
            color: $clr__error;
          }
        }
      }
      .info_wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .basket_price {
          text-align: right;
          float: right;
          margin-left: 50px;
          .price_title {
            display: inline-block;
            font-weight: $typo__fw--bold;
            color: #626262;
            font-size: 2em;
          }
          .price {
            display: inline-block;
            font-weight: $typo__fw--bold;
            color: #626262;
            font-size: 2.5em;
          }
          .price_mwst {
            display: block;
            font-weight: $typo__fw--light;
            color: #626262;
            font-size: 1.5em;
          }
        }
      }
    }
    .basket-chosen_payment {
      .total_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .basket_price {
          text-align: right;
          .netto,
          .vat {
            font-size: 1.5em;
            color: $clr__ci--tertiary;
            margin-bottom: 10px;
          }
          .total {
            font-size: 3.5em;
            color: $clr__ci--tertiary;
            font-weight: $typo__fw--bold;
          }
        }
      }
      .submit_1 {
        background-color: $clr__ci--secondary;
        border: 1px solid $clr__ci--secondary;
        border-radius: 23px;
        padding: 8px 45px;
        color: $clr__white;
        text-transform: uppercase;
        font-size: 1.6em;
        font-weight: $typo__fw--bold;
        display: inline-block;
        cursor: pointer;
        float: right;
        margin-top: 40px;
        &:hover {
          background-color: #707070;
          border-color: #707070;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-basket {
    .bn-basket_delete {
      .bn-basket_remove {
        font-size: 1.4em;
      }
    }
    .bn-basket_table {
      .bn-basket_table_wrap {
        > div {
          > div {
            .first-row,
            .row {
              font-size: 1.5em;
            }
            .first-row {
              font-size: 1.7em;
            }
          }
        }
      }
    }
    .basket-infos {
      .title {
        font-size: 2em;
      }
      .subtitle {
        font-size: 1.8em;
      }
      .text {
        font-size: 1.6em;
      }
      .basket-payment_mode {
        .basket_price {
          .price_title {
            font-size: 1.8em;
          }
          .price {
            font-size: 2.2em;
          }
        }
      }
      .basket-payment_method {
        .info_wrap {
          .basket_price {
            .price_title {
              font-size: 1.8em;
            }
            .price {
              font-size: 2.2em;
            }
          }
        }
      }
      .basket-chosen_payment {
        .total_wrap {
          .basket_price {
            .total {
              font-size: 3em;
            }
          }
        }
      }
    }
  }
}

// phablet
@media #{"(max-width: 650px)"}{
  .bn-basket {
    .bn-basket_table {
      .bn-basket_table_wrap {
        overflow-x: auto;
        > div {
          min-width: 610px;
        }
      }
    }
  }
}