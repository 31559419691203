/*  ==========================================================================
    QUICK REQUEST HORIZONTAL
    ========================================================================== */

@mixin quick-request--horizontal() {
  .bn_quickrequest_horizontal:not(.bn-dynformInit)>div:not(.bn-loader){
    display: none!important;
  }

  .bn_quickrequest_horizontal{
    width: 100%;
  }
  #bn_quickrequest_horizontal .bn-form__button-container .bn-form__button {
    border-color: #FF6C00!important;
    background-color: #FF6C00!important;
    &:hover {
      background-color: transparent!important;
      color: #fff!important;
    }
  }
  #bn_quickrequest_horizontal .bn-form__button span {
    text-transform: none!important;
    font-size: 1.3em;
    font-family: $typo__ff--primary;
  }
}